export enum validBrans {
  ariston = "ariston",
  candy = "candy",
  whirlpool = "whirlpool",
  eslabon = "eslabon de lujo",
  electrolux = "electrolux",
  philips = "philips",
  patrick = "patrick",
  longvie = "longvie",
  samsung = "samsung",
  gafa = "gafa",
  kohinoor = "koh-i-noor",
  generalelectrinc = "general electric",
  coventry = "coventry",
  bosch = "bosch",
  aurora = "aurora",
  indesit = "indesit",
  columbia = "columbia",
  whitewestinghouse = "white-westinghouse",
}
