import React from "react";
import logo from "../share/images/logo-xs-w.png";

const Header = ({ currentBrand }) => {
  return (
    <div className="bg-red-600 h-72 bg-header-img bg-cover ">
      <div className="bg-gray-950/50 h-full">
        {/* <h1 className='text-white pt-10 text-5xl font-bold'>ROMA SERVICE</h1> */}
        <div className="flex justify-center pt-5">
          <img className="w-80 md:w-96" src={logo} alt="Roma Serivice" />
        </div>
        <a href="tel:+5491128155302" target="blank">
          <h2 className="text-white pt-5 text-2xl md:text-3xl font-bold">
            (011) 15-2815-5302
          </h2>
        </a>
        <a href="mailto:romaservice.ar@gmail.com" target="blank">
          <h2 className="text-white pt-5 text-2xl md:text-3xl font-bold">
            romaservice.ar@gmail.com
          </h2>
        </a>
      </div>
    </div>
  );
};

export default Header;
