import React from "react";
import logo from "../share/images/logo-xs-w.png";
import { FaInstagram } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

const Footer = ({ currentBrand }) => {
  return (
    <div className="bg-slate-700">
      <div className="md:flex mt-32 pt-10  px-5 md:px-40">
        <div className="md:w-1/2">
          <img className="w-80 md:w-96" src={logo} alt="Roma Serivice" />
        </div>
        <div className="md:w-1/2 flex justify-center text-gray-200">
          <div className="pt-5">
            <FaClock />
          </div>
          <div className="pl-5">
            {" "}
            <p className="text-l text-left">Lunes a Viernes de 9 a 18 hs</p>
            <p className="text-l text-left">Sábados de 9 a 15 hs </p>
            <p className="text-l text-left">Domingos CERRADO</p>
          </div>
        </div>
      </div>
      <div className="">
        {!currentBrand && (
          <p className="text-l text-left p-5">
            Servicio técnico - Servicio - Servicio técnico lavarropas - Servicio
            técnico Ariston - Servicio técnico Candy - Servicio técnico
            Whirlpool - Servicio técnico Eslabon de lujo - Servicio técnico
            Electrolux - Servicio técnico Philips - Servicio técnico Patrick -
            Servicio técnico Longvie - Servicio técnico Samsung - Servicio
            técnico Gafa - Servicio técnico Koh-i-noor - Servicio técnico
            General Electric - Servicio técnico Coventry - Servicio técnico
            Bosch - Servicio técnico Aurora - Servicio técnico Indesit -
            Servicio técnico Columbia - Servicio técnico White-Westinghouse
          </p>
        )}
      </div>
      <div className="py-5 w-full">
        Developed by{" "}
        <a
          className="text-purple-600"
          href="https://ldgcode.com/"
          target="blank"
        >
          LDGCode
        </a>
      </div>
    </div>
  );
};

export default Footer;
