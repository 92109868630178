import React, { useEffect, useState } from "react";
import wmImg from "./../share/images/washing-machine.png";

import ariston from "./../share/images/marcas/ariston.png";
import candy from "./../share/images/marcas/candy.png";
import wpool from "./../share/images/marcas/whirlpool.png";
import eslabon from "./../share/images/marcas/eslabondelujo.png";
import electrolux from "./../share/images/marcas/electrolux.png";
import philips from "./../share/images/marcas/philips.png";
import patrick from "./../share/images/marcas/patrick.png";
import longvie from "./../share/images/marcas/longvie.png";
import samsung from "./../share/images/marcas/samsung.png";
import gafa from "./../share/images/marcas/gafa.jpg";
import peabody from "./../share/images/marcas/peabody.png";
import philco from "./../share/images/marcas/philco.png";
import kohinoor from "./../share/images/marcas/kohinoor.png";
import ge from "./../share/images/marcas/ge.png";
import coventry from "./../share/images/marcas/coventry.jpg";
import aurora from "./../share/images/marcas/aurora.jpg";
import indesit from "./../share/images/marcas/indesit.png";
import bosch from "./../share/images/marcas/bosch.jpg";
import columbia from "./../share/images/marcas/columbia.jpg";
import ww from "./../share/images/marcas/ww.png";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegHandPointer } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { validBrans } from "../utils/brandsValidator.tsx";

const Body = ({ currentBrand }) => {
  const [validBrand, setvalidBrand] = useState(false);
  function buttonHandler(contactBy) {
    switch (contactBy) {
      case "wp":
        window.open("https://wa.me/5491128155302", "_blank");

        break;
      case "tel":
        window.open("tel:+5491128155302", "_blank");

        break;

      default:
        break;
    }
    console.log(Object.values(validBrans).includes(currentBrand));
  }

  useEffect(() => {
    setvalidBrand(Object.values(validBrans).includes(currentBrand));
  }, [currentBrand]);

  const getCurrentImg = (currentBrant) => {
    console.log(currentBrand);
    console.log(validBrans.ariston);
    console.log(validBrans.ariston === currentBrand);
    switch (currentBrant) {
      case validBrans.ariston:
        return <img className="max-w-xs" src={ariston} alt="" />;
        break;
      case validBrans.aurora:
        return <img className="max-w-xs" src={aurora} alt="" />;
        break;
      case validBrans.bosch:
        return <img className="max-w-xs" src={bosch} alt="" />;
        break;
      case validBrans.candy:
        return <img className="max-w-xs" src={candy} alt="" />;
        break;
      case validBrans.columbia:
        return <img className="max-w-xs" src={columbia} alt="" />;
        break;
      case validBrans.coventry:
        return <img className="max-w-xs" src={coventry} alt="" />;
        break;
      case validBrans.electrolux:
        return <img className="max-w-xs" src={electrolux} alt="" />;
        break;
      case validBrans.eslabon:
        return <img className="max-w-xs" src={eslabon} alt="" />;
        break;
      case validBrans.gafa:
        return <img className="max-w-xs" src={gafa} alt="" />;
        break;
      case validBrans.generalelectrinc:
        return <img className="max-w-xs" src={ge} alt="" />;
        break;
      case validBrans.indesit:
        return <img className="max-w-xs" src={indesit} alt="" />;
        break;
      case validBrans.kohinoor:
        return <img className="max-w-xs" src={kohinoor} alt="" />;
        break;
      case validBrans.longvie:
        return <img className="max-w-xs" src={longvie} alt="" />;
        break;
      case validBrans.patrick:
        return <img className="max-w-xs" src={patrick} alt="" />;
        break;
      case validBrans.philips:
        return <img className="max-w-xs" src={philips} alt="" />;
        break;
      case validBrans.samsung:
        return <img className="max-w-xs" src={samsung} alt="" />;
        break;
      case validBrans.whirlpool:
        return <img className="max-w-xs" src={wpool} alt="" />;
        break;
      case validBrans.whitewestinghouse:
        return <img className="max-w-xs" src={ww} alt="" />;
        break;

      default:
        return <img className="max-w-xs" src={wmImg} alt="" />;
        break;
    }
  };

  return (
    <div>
      {/* <div>
                <p className='
                sm:text-red-500
                 md:text-green-500
                  lg:text-blue-500
                   xl:text-yellow-500
                    2xl:text-purple-500'>
                    sm: red, md:gree, lg:blue, xl:yellow, or 2xl: purple
                </p>
            </div> */}
      <div className="md:flex mt-20 md:mt-32 px-5  md:px-10 lg:px-48 xl:px-72">
        <div className="w-full md:w-1/2 flex justify-center ">
          <div className="text-left">
            {validBrand ? (
              <h2 className="text-4xl font-bol text-indigo-500">
                Servicio Técnico oficial de {currentBrand}
              </h2>
            ) : (
              <h2 className="text-4xl font-bold text-indigo-500">
                Servicio Técnico de Lavarropas
              </h2>
            )}
            <h2 className="text-2xl mt-3">
              Reparaciones en el día y con garantía escrita
            </h2>
            {!validBrand && (
              <h2 className="text-2xl mt-3">Trabajamos todas las marcas</h2>
            )}
          </div>
        </div>
        <div className="w-full mt-32 md:mt-0 md:w-1/2 flex justify-center">
          {getCurrentImg(currentBrand.toLowerCase())}
        </div>
      </div>

      <div className="md:flex md:m-32  p-5 dm:px-40">
        <div className="md:w-1/2">
          <div className="">
            <div className="flex justify-center">
              <ul className="text-left">
                <li className="mt-5 text-3xl font-bold md:flex justify-center pt-5">
                  <a
                    className="contents"
                    href="https://wa.me/5491128155302"
                    target="blank"
                  >
                    <FaWhatsapp />
                    <span className="md:pl-5 text-green-500 block">
                      WhatsApp:
                    </span>
                    <span className="md:pl-5">(011) 15-2815-5302</span>
                  </a>
                </li>
                <li className="mt-5 text-3xl font-bold md:flex justify-center pt-5">
                  <a
                    className="contents"
                    href="tel:+5491128155302"
                    target="blank"
                  >
                    <FaPhoneAlt className="min-w-fit" />
                    <span className="md:pl-5 text-indigo-500 block">Tel: </span>
                    <span className="pl-5 table-row-group">
                      (011) 15-2815-5302
                    </span>
                  </a>
                </li>
                <li className="mt-5 text-3xl font-bold md:flex justify-center pt-5">
                  <a
                    className="contents"
                    href="tel:+5491128155302"
                    target="blank"
                  >
                    <FaRegHandPointer className="min-w-fit" />

                    <span className="pl-5 table-row-group	">
                      llamar click aqui
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 text-left text-xl pt-5">
          <p className="pt-5">
            <strong className="text-2xl">R</strong>oma{" "}
            <strong className="text-2xl">S</strong>ervice es un emprendimiento
            nuevo que se enfoca en brindar servicios técnicos de calidad con un
            enfoque en la seriedad, responsabilidad y confiabilidad. Nos
            enorgullece proporcionar a nuestros clientes soluciones
            profesionales y personalizadas, con el compromiso de garantizar su
            satisfacción.{" "}
          </p>
          <p className="pt-5">
            Además, en Roma Service nos enfocamos en el crecimiento personal y
            profesional de nuestro equipo, brindándoles las herramientas
            necesarias para mantenerse actualizados en el mercado y brindar un
            servicio excepcional.
          </p>
          <p className="pt-5">
            Te invitamos a seguirnos en Instagram para estar al tanto de
            nuestras últimas novedades y promociones.
          </p>
          <p className="pt-5 text-center flex justify-center">
            ¡Haz clic en el icono de Instagram para seguirnos ahora!
          </p>
          <div className="flex justify-center">
            <a href="https://www.instagram.com/romaservice.ar/" target="blank">
              {" "}
              <FaInstagram className="mt-5 text-pink-600 c13584" size={50} />
            </a>
          </div>
        </div>
      </div>

      {/* <div className="md:flex justify-center my-32 px-5  md:px-40">
        <div className="">
          <div className="flex justify-center">
            <ul className="text-left">
              <li className="mt-5 text-3xl font-bold md:flex justify-center pt-5">
                <a
                  className="contents"
                  href="https://wa.me/5491128155302"
                  target="blank"
                >
                  <FaWhatsapp />
                  <span className="md:pl-5 text-green-500 block">
                    WhatsApp:
                  </span>
                  <span className="md:pl-5">(011) 15-2815-5302</span>
                </a>
              </li>
              <li className="mt-5 text-3xl font-bold md:flex justify-center pt-5">
                <a
                  className="contents"
                  href="tel:+5491128155302"
                  target="blank"
                >
                  <FaPhoneAlt className="min-w-fit" />
                  <span className="md:pl-5 text-indigo-500 block">Tel: </span>
                  <span className="pl-5 table-row-group">
                    (011) 15-2815-5302
                  </span>
                </a>
              </li>
              <li className="mt-5 text-3xl font-bold md:flex justify-center pt-5">
                <a
                  className="contents"
                  href="tel:+5491128155302"
                  target="blank"
                >
                  <FaRegHandPointer className="min-w-fit" />

                  <span className="pl-5 table-row-group	">
                    llamar click aqui
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      {!validBrand && (
        <>
          <hr />

          <div>
            <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
              <div className="-m-1 flex flex-wrap md:-m-2">
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full  w-full rounded-lg object-contain object-center"
                      src={ariston}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={candy}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={wpool}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={eslabon}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={electrolux}
                    />
                  </div>
                </div>

                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={philips}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={patrick}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={longvie}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={samsung}
                    />
                  </div>
                </div>

                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={gafa}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={peabody}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={philco}
                    />
                  </div>
                </div>

                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={kohinoor}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={ge}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={coventry}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={bosch}
                    />
                  </div>
                </div>

                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={aurora}
                    />
                  </div>
                </div>

                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={indesit}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={columbia}
                    />
                  </div>
                </div>
                <div className="flex w-1/5 flex-wrap">
                  <div className="w-full p-1 md:p-2">
                    <img
                      alt="gallery"
                      className="max-h-32 block h-full w-full rounded-lg object-contain object-center"
                      src={ww}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}

      <div className="mt-32">
        <h2 className="text-3xl font-bold text-indigo-500">Sucursales:</h2>
        <div className="flex justify-center">
          <ul className="text-left locationIcon">
            <li className="mt-5 text-2xl p-5 sm:p-0 sm:text-3xl flex justify-left ">
              <CiLocationOn />
              <span className="pl-5">Manuela pedraza 1713 (Nuñez)</span>
            </li>
            <li className="mt-5 text-2xl p-5 sm:p-0 sm:text-3xl flex justify-left">
              <CiLocationOn />
              <span className="pl-5">Maipu 1468 (Vicente López)</span>
            </li>
            <li className="mt-5 text-2xl p-5 sm:p-0 sm:text-3xl flex justify-left">
              <CiLocationOn />
              <span className="pl-5">Sarmiento 1477 (Caballito)</span>
            </li>
            <li className="mt-5 text-2xl p-5 sm:p-0 sm:text-3xl flex justify-left">
              <CiLocationOn />
              <span className="pl-5">Santo Tome 3097 (Villa del parque)</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="md:flex mt-32  px-5 dm:px-40">
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold text-indigo-500">ZONAS</h2>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold">CABA:</h2>
          <p className="text-left pt-5">
            Agronomía - Almagro - Balvanera - Barracas - Barrio Norte - Belgrano
            - Boedo - Caballito - Centro - Chacarita - Coghlan - Colegiales -
            Congreso - Constitución - Flores - Floresta - La Boca - Liniers -
            Mataderos - Nuñez - Once - Palermo - Parque Centenario - Parque
            Chacabuco - Parque Chas - Parque Patricios - Paternal - Puerto
            Madero - Recoleta - Retiro - Saavedra - San Cristóbal - San Telmo -
            Tribunales - Versailles - Villa Crespo - Villa Devoto - Villa Luro -
            Villa Ortuzar - Villa Pueyrredón - Villa Urquiza - Villa Del Parque.
          </p>
        </div>
      </div>

      <button
        onClick={() => buttonHandler("wp")}
        title="Whatsapp"
        className="fixed z-90 bottom-10 right-8 bg-green-500 w-10 md:w-16 h-10 md:h-16 rounded-full 
                drop-shadow-lg flex justify-center items-center text-white text-2xl md:text-4xl
                 hover:bg-green-600 hover:drop-shadow-2xl hover:animate-bounce duration-300"
      >
        <FaWhatsapp />
      </button>
      <button
        onClick={() => buttonHandler("tel")}
        title="Whatsapp"
        className="fixed z-90 bottom-10 left-8 bg-green-500 w-10 md:w-16 h-10 md:h-16 rounded-full 
                drop-shadow-lg flex justify-center items-center text-white text-2xl md:text-4xl
                 hover:bg-green-600 hover:drop-shadow-2xl hover:animate-bounce duration-300"
      >
        <FaPhoneAlt />
      </button>
    </div>
  );
};

export default Body;
