import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import Header from "./components/Header.tsx";
import Body from "./components/Body.tsx";
import Footer from "./components/Footer.tsx";
import { useParams } from "react-router-dom";

function App({ brand }) {
  const [currentBrand, setCurrentBrand] = useState(brand);
  let { brandName } = useParams();

  useEffect(() => {
    console.log("brand", brandName);
    setCurrentBrand(window.location.pathname.toLowerCase().slice(1));
  }, [brandName]);

  return (
    <div className="App">
      <header>
        <Header currentBrand={currentBrand} />
      </header>

      <body>
        <Body currentBrand={currentBrand} />
      </body>

      <footer>
        <Footer currentBrand={currentBrand} />
      </footer>
    </div>
  );
}

export default App;
